<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
            :items="crumbs"
            class="capitalize"
            style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" text x-small @click.stop="newDialog = true">
          new service
        </v-btn>
        <ServiceNew
            :newDialog="newDialog"
            :subType="2"
            @closeNew="closeNew"
            @submitNew="submitNew"
        />
        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card flat>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="servicelist"
              :items-per-page="10"
              :loading="loading"
              loader-height="1"
          >

            <template v-slot:item.name="{ item }">
              {{ item.name|capitalize }}
            </template>
            <template v-slot:item.physical_location="{ item }">
              {{ item.physical_location|capitalize }}
            </template>

            <template v-slot:item.active="{ item }">
              <v-icon :color="item.active ? 'primary' : 'error'">
                {{ item.active ? "mdi-check" : "mdi-close" }}
              </v-icon>
            </template>

            <template v-slot:item.head_office="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :color="item.head_office ? 'indigo':'red'" icon small v-on="on"
                         @click.stop="$set(headDialog, item.id, true)">
                    <v-icon>
                      {{ item.head_office ? "mdi-check" : "mdi-close" }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Does <b style="text-transform: capitalize">{{ item.name }}</b> represent the head office?</span>
              </v-tooltip>
              <ServiceHeads
                  v-if="headDialog[item.id]"
                  :headDialog="headDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  @closeHead="closeHead"
                  @submitHead="submitHead"/>
            </template>

            <template v-slot:item.updated="{ item }">
              {{ item.updated | myDating }}
            </template>

            <template v-slot:item.id="{ item }">
              <v-btn
                  color="success"
                  text
                  x-small
                  @click.stop="$set(viewDialog, item.id, true)"
              >
                view
              </v-btn>
              <ServiceView
                  v-if="viewDialog[item.id]"
                  :myId="item.id"
                  :name="item.name"
                  :viewDialog="viewDialog[item.id]"
                  @closeView="closeView"/>

              <v-btn
                  color="primary"
                  text
                  x-small
                  @click.stop="$set(editDialog, item.id, true)"
              >
                edit
              </v-btn>
              <ServiceEdit
                  v-if="editDialog[item.id]"
                  :editDialog="editDialog[item.id]"
                  :myId="item.id"
                  @closeEdit="closeEdit"
                  @submitEdit="submitEdit"/>

              <v-btn
                  color="error"
                  text
                  x-small
                  @click.stop="$set(removeDialog, item.id, true)"
              >
                remove
              </v-btn>
              <ServiceDelete
                  v-if="removeDialog[item.id]"
                  :myId="item.id"
                  :removeDialog="removeDialog[item.id]"
                  @closeRemove="closeRemove"
                  @submitRemove="submitRemove"
              />

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :to="{name:'lab.centers.detail', params:{id: _encode(item.id), name:_encode(item.name)}}"
                         color="indigo" text
                         x-small
                         v-on="on">
                    services
                  </v-btn>
                </template>
                <span>Services offered by <b style="text-transform: capitalize">{{ item.name }}</b></span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-snackbar
          v-model="snack.bar"
          :color="snack.color"
          :multi-line="snack.multiLine"
      >
        {{ snack.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Utils from "../../mixins/utils";
import ServiceNew from "../../components/subsidiaries/centers/newone.vue";
import ServiceHeads from "../../components/subsidiaries/centers/heads.vue";
import ServiceView from "../../components/subsidiaries/centers/view.vue";
import ServiceEdit from "../../components/subsidiaries/centers/edit.vue";
import ServiceDelete from "../../components/subsidiaries/centers/remove.vue";

export default {
  components: {ServiceNew, ServiceHeads, ServiceView, ServiceEdit, ServiceDelete},
  mixins: [Utils, WinScroll("position")],
  data() {
    return {
      headDialog: {},
      editDialog: {},
      viewDialog: {},
      removeDialog: {},
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      loading: false,
      newDialog: false,
      crumbs: [
        {
          link: true,
          text: "Dashboard",
          to: {name: "lab.dash"},
        },
        {
          link: true,
          text: "Centers",
          to: {name: "lab.centers"},
        },
      ],
      servicelist: [],
      headers: [
        {text: "subsidiary", value: "name"},
        // {text: "description", value: "description"},
        {text: "location", value: "physical_location"},
        {text: "Head Office", value: "head_office", align: 'center'},
        {text: "active", value: "active"},
        {text: "updated", value: "updated"},
        {text: "action", value: "id"},
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subsidiary
          .list()
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              this.servicelist = response.data;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    refresh() {
      this.list();
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
          .create(p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.newDialog = p.state;
    },
    closeHead(p) {
      this.$set(this.headDialog, p.id, p.state);
    },
    submitHead(p) {
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
          .updateHead(p.id, p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.headDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers.subsidiary
          .update(p.id, p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers.subsidiary
          .remove(p.id)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
